// const pointType = [
//   { key: 1, value: "活动签到" },
//   { key: 2, value: "扫码获取" },
//   { key: 3, value: "未来心愿" },
//   { key: 4, value: "社区共建" },
//   { key: 5, value: "公益捐赠" },
//   { key: 6, value: "活动发布" },
//   { key: 7, value: "运动打卡" },
//   { key: 9, value: "积分商城兑换" },
//   { key: 0, value: "积分扫码扣除" },
//   { key: 12, value: "每日签到" },
//   { key: 11, value: "用户注册" },
//   { key: 13, value: "点赞任务" },
//   { key: 14, value: "评论任务" },
//   { key: 15, value: "完善资料" },
//   { key: 16, value: "与小和微信互动" },
//   { key: 17, value: "超话活动" },
//   { key: 18, value: "邻里公约" },
//   { key: 19, value: "学习签到" },
// ];
const pointType = [
  { key: 0, value: "扫码扣除" },
  { key: 1, value: "活动签到" },
  { key: 2, value: "扫码获取" },
  { key: 3, value: "未来心愿" },
  { key: 4, value: "社区共建" },
  { key: 5, value: "公益捐赠" },
  { key: 6, value: "活动发布" },
  { key: 7, value: "运动打卡" },
  { key: 8, value: "社区达人申请" },
  { key: 9, value: "商城兑换" },
  { key: 11, value: "用户注册" },
  { key: 12, value: "每日签到" },
  { key: 13, value: "点赞任务" },
  { key: 14, value: "评论任务" },
  { key: 15, value: "完善资料" },
  { key: 16, value: "与小和微信互动" },
  { key: 17, value: "超话活动" },
  { key: 18, value: "党建在线学习" },
  { key: 19, value: "邻里公约" },
  { key: 20, value: "访问商城" },
  { key: 21, value: "访问小和超话" },
  { key: 22, value: "访问小和广播站" },
  { key: 23, value: "访问活动广场" },
  { key: 24, value: "组合任务额外加分" },
  { key: 25, value: "社群场地预约" },
  { key: 26, value: "商圈扫码" },
  { key: 27, value: "时间银行签到" },
  { key: 28, value: "运动打卡" },
  { key: 99, value: "转赠" },
  { key: 105, value: "申请志愿者" },
  { key: 95, value: "特殊人群帮扶" },
];

export { pointType };
